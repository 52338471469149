import clx from '@wander/ui/src/utils/clx';

import { PropertyLandingPage } from '@/features/properties/actions/toLandingProperty';
import { mapToImages } from '../../../actions/image';
import CarouselImages from '../CarouselImage';

type Props = {
  property: {
    // biome-ignore lint/suspicious/noExplicitAny: any
    medias: any;
    name: string;
    animatedHeroVideo?: PropertyLandingPage['animatedHeroVideo'];
  };
  className?: string;
  imagesClassName?: string;
  loading?: 'lazy' | 'eager';
  fetchPriority?: 'auto' | 'high';
};

const Property_Carousel = ({
  property,
  className,
  imagesClassName,
  loading = 'lazy',
  fetchPriority = 'auto',
}: Props) => {
  const images = mapToImages(property.medias);
  return (
    <div className={clx('card relative isolate h-full w-full overflow-hidden rounded-lg', className)}>
      {images.length ? (
        <CarouselImages
          images={images}
          alt={property.name}
          className={clx('h-full w-full object-cover', imagesClassName)}
          loading={loading}
          fetchPriority={fetchPriority}
          animatedHeroVideo={property.animatedHeroVideo}
          width={640}
          height={427}
        />
      ) : undefined}
    </div>
  );
};

export default Property_Carousel;
