import useUser from '@/features/user/hooks/useUser';
import useSWRImmutable from 'swr/immutable';
import getUserWaitList from '../actions/getUserWaitList';

const useIsWaitListed = (propertyId: string) => {
  const { user } = useUser();

  const { data: subscriptions, isLoading } = useSWRImmutable(
    user?.id ? ['waitlist', user.id] : undefined,
    getUserWaitList
  );

  if (isLoading) {
    return undefined;
  }

  return subscriptions?.includes(propertyId) || false;
};

export default useIsWaitListed;
