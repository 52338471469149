'use client';

import { createContext, useContext, useEffect, useState } from 'react';

import { useSearchParams } from 'next/navigation';

export type SearchContext = {
  isCalendarOpen: boolean;
  toggleCalendarFilter: () => void;
  isLocationOpen: boolean;
  toggleLocationFilter: () => void;
  isGuestOpen: boolean;
  toggleGuestFilter: () => void;
  loadMore: () => void;
  search: (searchParams?: string, prefetch?: boolean) => void;
  basePathname: string;
  closeFilters: () => void;
};

const Context = createContext<SearchContext>({} as SearchContext);

const SearchProvider_Context = ({
  children,
  value,
}: React.PropsWithChildren<{
  value: SearchContext;
}>) => {
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default SearchProvider_Context;

export const useSearch = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('useSearch must be used within a SearchProvider');
  }

  return context;
};

export const useSearchQuery = () => {
  let searchQueryString = useSearchParams()?.toString();

  // useEffect(() => {
  //   if (`?${searchQueryString}` !== window.location.search) {
  //     setSearchQueryString(window.location.search);
  //   }
  // }, [searchQueryString]);

  if (searchQueryString?.search('chat') != -1) {
    if (searchQueryString === 'chat') {
      searchQueryString = '';
    } else {
      const searchParams = new URLSearchParams(searchQueryString);
      searchParams.delete('chat');
      searchQueryString = searchParams.toString();
    }
  }

  return searchQueryString.replace('?', '');
};
