import client from '@/services/fetcher/client';

const joinWaitList = async (unitId: string, phoneNumber: string) => {
  return client.authFetch(`/guest-api/listings/${unitId}/waitlist`, {
    method: 'POST',
    cache: 'no-cache',
    body: JSON.stringify({
      unitId,
      phoneNumber: '+' + phoneNumber.replace(/\D/g, ''),
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export default joinWaitList;
