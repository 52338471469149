type PropertyLike = {
  address: {
    city: string;
    state: string;
  };
};

const getLocation = (property: PropertyLike) => `${property.address.city}, ${property.address.state}`;

export default getLocation;
