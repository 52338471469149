'use client';

import { useSearchQuery } from '../Search/SearchProvider/SearchProvider_Context';

const useLinkToProperty = (property: {
  slug: string;
  name: string;
}) => {
  const searchQueryString = useSearchQuery();
  return {
    href: `/property/${property.slug}${searchQueryString ? `?${searchQueryString}` : ''}`,
    'aria-label': property.name,
  };
};

export default useLinkToProperty;
