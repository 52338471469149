import type { AssetQualityType, UnitMediaTypeType } from '@wandercom/prisma-defs';

import { getPngUrl, getUrl } from '@/features/asset/actions/asset';
import type { Property as P } from './getProperty';
import type { PropertyRestricted } from './getPropertyRestricted';

type Property = P | PropertyRestricted;

type Asset = {
  thumbHash: Property['medias'][0]['thumbHash'];
  assetType?: Property['medias'][0]['assetType'];
  type: Property['medias'][0]['type'];
} & Partial<
  Pick<
    Property['medias'][0],
    | 'highestQuality'
    | 'fullResUrl'
    | 'highResUrl'
    | 'mediumResUrl'
    | 'lowResUrl'
    | 'pngFullResUrl'
    | 'pngHighResUrl'
    | 'pngMediumResUrl'
    | 'pngLowResUrl'
  >
>;

type PropertyLike = {
  medias: Asset[];
  rooms?: {
    medias: Asset[];
  }[];
};

export const getImage = (property: PropertyLike, type?: UnitMediaTypeType) => {
  const images = property.medias.filter((i) => (i.assetType ? i.assetType === 'IMAGE' : true));
  return type ? images.find((i) => i.type == type) || images[0] : images[0];
};

export type MappedImage = {
  thumbHash: string;
  url: string;
  type: UnitMediaTypeType;
};

const mapToImage =
  (quality?: AssetQualityType) =>
  (image: Asset): MappedImage => ({
    thumbHash: image.thumbHash,
    url: getUrl(image, quality),
    type: image.type,
  });

export const mapToImages = (images: Asset[], quality?: AssetQualityType) => images.map(mapToImage(quality));

export const getImages = (property: PropertyLike, quality?: AssetQualityType) => {
  const rooms = property.rooms || [];
  const images = [...property.medias, ...rooms.flatMap((i) => [...i.medias])].filter(
    (i) => i.assetType === 'IMAGE' && i.type !== 'FLOOR_PLAN'
  );
  return mapToImages(images, quality);
};

export const getImageUrl = (property: PropertyLike, type?: UnitMediaTypeType, quality?: AssetQualityType) =>
  getUrl(getImage(property, type), quality);

export const getImagePngUrl = (property: PropertyLike, type?: UnitMediaTypeType, quality?: AssetQualityType) =>
  getPngUrl(getImage(property, type), quality);
