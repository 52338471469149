'use client';

import { useEffect, useRef } from 'react';

import Icon from '@wander/ui/src/components/Icon';

const WaitListJoined = () => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    ref.current?.closest('label')?.classList.remove('cursor-pointer');
  }, []);
  return (
    <div ref={ref} className='flex h-5 items-center text-sm text-success'>
      <Icon name='checkmark' className='top-0 mr-1 h-3 w-3' />
      Waitlist joined
    </div>
  );
};

export default WaitListJoined;
