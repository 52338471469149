'use client';

import Icon from '@wander/ui/src/components/Icon';

import useUser from '@/features/user/hooks/useUser';

type Props = React.PropsWithChildren<{ slug: string }>;
const JoinWaitListWithUserWrapper = ({ slug, children }: Props) => {
  const { user, toggleLoginDialog } = useUser();

  if (!user) {
    return (
      <button
        id={`join-waitlist-${slug}`}
        className='flex items-center text-sm text-w-3005'
        onClick={() => {
          window.location.hash = `#join-${slug}`;
          toggleLoginDialog();
        }}
      >
        Join the waitlist <Icon name='chevron-right' className='top-0 h-[20px] w-[20px]' />
      </button>
    );
  }

  return children;
};

export default JoinWaitListWithUserWrapper;
