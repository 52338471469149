'use client';

import { lazy, useCallback, useEffect, useRef, useTransition } from 'react';

import ButtonAction from '@wander/ui/src/components/Button/ButtonAction';
import Dialog from '@wander/ui/src/components/Dialog';
import Icon from '@wander/ui/src/components/Icon';
import ImageWithThumbHash from '@wander/ui/src/components/Image/ImageWithThumbHash';
import useToggle from '@wander/ui/src/hooks/useToggle';
import { useNotify } from '@wander/ui/src/providers/NotificationsProvider';

import { getUrl } from '@/features/asset';
import useUser from '@/features/user/hooks/useUser';
import { getImage } from '../../../actions/image';
import joinWaitList from '../../../actions/joinWaitlist';
import type { PropertyLandingPage } from '../../../actions/toLandingProperty';
// TODO - move to shared
import updateUserPhone from '../../../actions/updateUserPhone';
import updateUserWaitList from '../../../actions/updateUserWaitList';

const InputPhoneWithLabel = lazy(() => import('@wander/ui/src/components/Input/InputPhoneWithLabel'));

type Props = {
  property: PropertyLandingPage;
};

const JoinWaitListDialog = ({ property }: Props) => {
  const { user } = useUser();
  const image = getImage(property);
  const notify = useNotify();

  const [isOpen, _, open, close] = useToggle(false);
  const formRef = useRef<HTMLFormElement>(null);

  const [isPending, startTransition] = useTransition();

  const handleSubmit = useCallback(
    async (formData: FormData) => {
      const phoneNumber = formData.get('phone');
      if (user?.id && phoneNumber && !['+', '+1', ''].includes(phoneNumber as string)) {
        startTransition(async () => {
          try {
            await updateUserPhone(phoneNumber as string);
            await joinWaitList(property.id, phoneNumber as string);
            await updateUserWaitList(user.id, property.id);
            startTransition(() => {
              close();
            });
          } catch {
            notify.error({
              title: 'Something went wrong.',
              description: 'Ensure phone number is valid',
            });
          }
        });
      } else {
        formRef.current?.querySelector('input')?.focus();
      }
    },
    [close, notify.error, property.id, user.id]
  );

  useEffect(() => {
    if (window.location.hash === `#join-${property.slug}`) {
      try {
        history.replaceState('', '', `${location.pathname}${location.search}`);
        open();
      } catch {}
    }
  }, [open, property.slug]);

  if (!user) {
    return null;
  }

  return (
    <Dialog
      open={isOpen}
      className='h-screen w-screen sm:h-auto sm:max-w-[500px] lg:min-w-0'
      closeButtonClassName='text-white'
      onClose={close}
      trigger={
        <button
          onClick={() => open()}
          id={`join-waitlist-${property.slug}`}
          className='flex items-center text-sm text-w-3005'
        >
          Join the waitlist <Icon name='chevron-right' className='top-0 h-[20px] w-[20px]' />
        </button>
      }
    >
      <div className='w-full overflow-hidden rounded-lg'>
        {image ? (
          <ImageWithThumbHash
            src={getUrl(image)}
            thumbHash={image.thumbHash}
            alt={property.name}
            className='h-[300px] w-full object-cover'
            width={500}
            height={300}
          />
        ) : null}
        <form
          ref={formRef}
          action={handleSubmit}
          className='relative w-full bg-1-black px-6 pb-6 before:absolute before:bottom-full before:left-0 before:h-24 before:w-full before:bg-gradient-to-b before:from-transparent before:to-1-black before:content-[""]'
        >
          <div className='text-property-eyebrow text-w-3005'>Join the waitlist</div>
          <div className='mb-2 font-headline text-4.5xl font-bold'>{property.name}</div>
          <p className='mb-5 text-w-3005'>
            Adventure awaits. Be notified first via text when this location goes live at a limited-time, special launch
            price.
          </p>
          <InputPhoneWithLabel
            className='mb-6'
            placeholder='Enter phone number here'
            name='phone'
            defaultValue={user.phoneNumber || ''}
          />
          <ButtonAction loading={isPending} className='btn w-full'>
            Join the waitlist
          </ButtonAction>
        </form>
      </div>
    </Dialog>
  );
};

export default JoinWaitListDialog;
