'use client';

import { Suspense, lazy, memo, useCallback, useEffect, useRef, useState } from 'react';

import Icon from '@wander/ui/src/components/Icon';
import ImageWithThumbHash from '@wander/ui/src/components/Image/ImageWithThumbHash';
import { useMinSize } from '@wander/ui/src/hooks/useWindowSize';
import clx from '@wander/ui/src/utils/clx';
import { useInView } from 'framer-motion';

import isTouchDevice from '@/utils/isTouchDevice';
import onScreenObserver from '@/utils/onScreenObserver';
import clsx from 'clsx';
import { PropertyLandingPage } from '../../actions/toLandingProperty';

const PaginationDot = lazy(() => import('@wander/ui/src/components/PaginatedDots'));

const PrevNextButton = ({ className, ...props }: React.HTMLProps<HTMLButtonElement>) => (
  <button
    className={clx(
      'pointer-events-auto absolute top-1/2 z-50 hidden h-7 w-7 -translate-y-1/2 place-items-center rounded-full bg-black/40 text-w-3005 opacity-0 backdrop-blur-xs transition-opacity hover:text-white group-hover:opacity-100 md:grid',
      className
    )}
    {...props}
    type='button'
  />
);

type ImageT = {
  url: string;
  thumbHash: string;
};

type CarouselImageProps = Omit<React.ComponentProps<typeof ImageWithThumbHash>, 'src' | 'thumbHash'> & {
  image: ImageT;
  width: number;
  height: number;
};

const CarouselImage = ({ image, alt, ...props }: CarouselImageProps) => (
  <ImageWithThumbHash
    {...props}
    src={image.url}
    thumbHash={image.thumbHash}
    alt={alt}
    className='h-full w-full flex-none snap-center snap-always overflow-hidden object-cover'
    quality={50}
  />
);

type CarouselVideoProps = {
  video: PropertyLandingPage['animatedHeroVideo'];
  thumbnail: ImageT;
  width: number;
  height: number;
};

const CarouselVideo = ({ video, thumbnail, width, height }: CarouselVideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const minSize = useMinSize();
  const isMobile = minSize != null && !minSize.md();
  const isInView = useInView(videoRef, { amount: 'all' });
  const [prefersReducedMotion] = useState(
    () => global?.window && window.matchMedia('(prefers-reduced-motion: reduce)').matches
  );

  const cancelPlaybackTimeoutRef = useRef<NodeJS.Timeout>();

  const [isPlaybackCancelled, setIsPlaybackCancelled] = useState(false);
  const [hasPlayed, setHasPlayed] = useState(false);
  const [hasLoadedData, setHasLoadedData] = useState(false);

  const startPlaying = useCallback(() => {
    // If video has not started playing for a few seconds, cancel.
    // Otherwise it looks to jarring to the user.
    if (!hasLoadedData) {
      cancelPlaybackTimeoutRef.current = setTimeout(() => {
        setIsPlaybackCancelled(true);
      }, 2000);
    }

    videoRef.current?.play();
    setHasPlayed(true);
  }, [hasLoadedData]);

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    let timeoutId: NodeJS.Timeout | null = null;

    if (isMobile && isInView) {
      timeoutId = setTimeout(() => {
        startPlaying();
      }, 1000);
    } else if (isMobile && !isInView) {
      videoRef.current?.pause();
    }

    return () => {
      if (timeoutId != null) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
    };
  }, [isMobile, isInView, prefersReducedMotion, startPlaying]);

  const handlePointerToggle = (isHovering: boolean) => {
    if (isMobile || prefersReducedMotion) {
      return;
    }

    if (isHovering) {
      startPlaying();
    } else {
      videoRef.current?.pause();
    }
  };

  return (
    <div
      className={clsx(
        'h-full w-full absolute inset-0 transition-opacity duration-700',
        !isPlaybackCancelled && hasPlayed && hasLoadedData ? 'opacity-0 hover:opacity-100' : 'opacity-0'
      )}
    >
      <video
        ref={videoRef}
        poster={thumbnail.url}
        preload='none'
        className='h-full w-full flex-none snap-center snap-always overflow-hidden object-cover'
        playsInline
        loop
        muted
        width={width}
        height={height}
        onLoadedData={() => {
          setHasLoadedData(true);

          // Since data is now loaded, clear the timeout
          if (cancelPlaybackTimeoutRef.current != null) {
            clearTimeout(cancelPlaybackTimeoutRef.current);
          }
        }}
        onPointerEnter={() => handlePointerToggle(true)}
        onPointerLeave={() => handlePointerToggle(false)}
      >
        <source src={video.lowResUrl} type='video/mp4' />
      </video>
    </div>
  );
};

const MAX_IMAGE = 12;

type Props = {
  images: ImageT[];
  animatedHeroVideo?: PropertyLandingPage['animatedHeroVideo'] | null;
  width: number;
  height: number;
};

const CarouselImages = memo(
  ({
    images,
    className,
    width,
    height,
    alt,
    animatedHeroVideo,
    ...props
  }: Omit<React.ComponentProps<typeof ImageWithThumbHash>, 'src' | 'thumbHash'> & Props) => {
    images.length = images.length > MAX_IMAGE ? MAX_IMAGE : images.length;

    const [firstImage, ...restImages] = images;
    const sliderRef = useRef<HTMLDivElement>(null);
    const [displayedImageIndex, setDisplayedImageIndex] = useState(0);
    const [showCarousel, setShowCarousel] = useState(false);
    const nextImageIndex = displayedImageIndex + 1;
    const prevImageIndex = displayedImageIndex - 1 < 0 ? images.length - 1 : displayedImageIndex - 1;

    const handleImageChange = (index: number) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      const nextImage = index % images.length;
      sliderRef.current?.scrollTo({
        left: nextImage * sliderRef.current.offsetWidth,
        behavior: 'smooth',
      });

      setDisplayedImageIndex(nextImage);
    };

    const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
      event.preventDefault();

      if (sliderRef.current) {
        setDisplayedImageIndex(Math.round(sliderRef.current.scrollLeft / sliderRef.current.offsetWidth));
      }
    };

    useEffect(() => {
      if (!sliderRef.current) {
        return;
      }

      if (isTouchDevice()) {
        const observer = onScreenObserver();
        const unobserve = observer.onIntersection(sliderRef.current, () => {
          setShowCarousel(true);
        });
        return () => unobserve();
      }
    }, []);

    return (
      <>
        <div
          ref={sliderRef}
          className={clx(
            'scrollbar-hide pointer-events-auto relative inset-0 flex h-full w-full snap-x snap-mandatory overflow-x-auto',
            className,
            'h-full'
          )}
          onScroll={handleScroll}
          onPointerEnter={() => setShowCarousel(true)}
        >
          {firstImage && (
            <div className='h-full w-full flex-none snap-center snap-always overflow-hidden relative'>
              <CarouselImage image={firstImage} alt={alt} width={width} height={height} {...props} />
              {animatedHeroVideo && (
                <CarouselVideo video={animatedHeroVideo} thumbnail={firstImage} width={width} height={height} />
              )}
            </div>
          )}
          {restImages && showCarousel
            ? restImages.map((image, index) => (
                <CarouselImage key={index} image={image} alt={alt} width={width} height={height} {...props} />
              ))
            : null}
        </div>
        <PrevNextButton aria-label='prev picture' className='left-4' onClick={handleImageChange(prevImageIndex)}>
          <Icon name='chevron-left' className='top-0 h-5 w-5' />
        </PrevNextButton>
        <PrevNextButton aria-label='next picture' className='right-4' onClick={handleImageChange(nextImageIndex)}>
          <Icon name='chevron-right' className='left-[1px] top-0 h-5 w-5' />
        </PrevNextButton>
        <Suspense fallback={null}>
          <PaginationDot
            curPage={displayedImageIndex}
            maxPage={images.length}
            className='absolute bottom-0 left-1/2 z-40 -translate-x-1/2'
          />
        </Suspense>
      </>
    );
  }
);

CarouselImages.displayName = 'CarouselImages';

export default CarouselImages;
