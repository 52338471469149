import Icon from '@wander/ui/src/components/Icon';
import clx from '@wander/ui/src/utils/clx';

import type { PropertyLandingPage } from '../../actions/toLandingProperty';

export const Feature = ({ icon, label }: { icon: string; label: string }) => (
  <>
    <Icon name={icon} className='text-4-light-grey ml-1' />
    <span className='whitespace-nowrap'>{label}</span>
  </>
);

type Props = {
  property: Pick<PropertyLandingPage, 'bedrooms' | 'bathrooms' | 'occupancy'>;
  className?: string;
  small?: boolean;
};

const Property_Features = ({ property, className, small = false }: Props) => (
  <div className={clx('text-sm', className)}>
    <Feature icon='bedroom' label={small ? String(property.bedrooms) : `${property.bedrooms} bedrooms`} />
    <Feature icon='bathroom' label={small ? String(property.bathrooms) : `${property.bathrooms} bathrooms`} />
    <Feature icon='people' label={small ? String(property.occupancy) : `Up to ${property.occupancy}`} />
  </div>
);

export default Property_Features;
