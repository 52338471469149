import Icon from '@wander/ui/src/components/Icon';
import clx from '@wander/ui/src/utils/clx';

type Props = {
  className?: string;
};

const AddToWishListFallback = ({ className }: Props) => {
  return (
    <div className={clx('btn btn-rounded btn-invert !h-8 !w-8 !bg-black/20', className)}>
      <Icon name='star' className='top-0 h-5 w-5 transition-opacity duration-200 text-transparent' />
    </div>
  );
};

export default AddToWishListFallback;
