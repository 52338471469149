import type { AssetQualityType, Asset as AssetT } from '@wandercom/prisma-defs';

export type Asset = Pick<AssetT, 'thumbHash'> &
  Partial<
    Pick<
      AssetT,
      | 'highestQuality'
      | 'fullResUrl'
      | 'highResUrl'
      | 'mediumResUrl'
      | 'lowResUrl'
      | 'lowestResUrl'
      | 'pngFullResUrl'
      | 'pngHighResUrl'
      | 'pngMediumResUrl'
      | 'pngLowResUrl'
      | 'pngLowestResUrl'
    >
  >;

const getUrlForQuality = (image: Asset, quality: AssetQualityType = 'LOW'): string => {
  switch (quality) {
    case 'ORIGINAL':
    case 'ORIGINAL_MAX':
      return image?.fullResUrl as string;
    case 'HIGH':
      return image?.highResUrl || getUrlForQuality(image, 'MEDIUM');
    case 'MEDIUM':
      return image?.mediumResUrl || getUrlForQuality(image, 'LOW');
    case 'LOW':
      return image?.lowResUrl || getUrlForQuality(image, 'LOWEST');
    case 'LOWEST':
      return image?.lowestResUrl || getUrlForQuality(image, 'ORIGINAL');
  }
};

export const getUrl = (asset: Asset, quality?: AssetQualityType) => getUrlForQuality(asset, quality);

const getPngUrlForQuality = (image: Asset, quality: AssetQualityType = 'LOW'): string => {
  switch (quality) {
    case 'ORIGINAL':
    case 'ORIGINAL_MAX':
      return image.pngFullResUrl as string;
    case 'HIGH':
      return image.pngHighResUrl || getPngUrlForQuality(image, 'MEDIUM');
    case 'MEDIUM':
      return image.pngMediumResUrl || getPngUrlForQuality(image, 'LOW');
    case 'LOW':
      return image.pngLowResUrl || getPngUrlForQuality(image, 'LOWEST');
    case 'LOWEST':
      return image.pngLowestResUrl || getPngUrlForQuality(image, 'ORIGINAL');
  }
};

export const getPngUrl = (asset: Asset, quality?: AssetQualityType) => getPngUrlForQuality(asset, quality);
