import clx from '../utils/clx';

type Props = {
  className?: string;
};

const Badge = ({ children, className }: React.PropsWithChildren<Props>) => {
  return (
    <div
      className={clx(
        className,
        'inline-flex h-[18px] w-min select-none items-center gap-1 whitespace-nowrap rounded-sm px-1 text-xs'
      )}
    >
      {children}
    </div>
  );
};

export default Badge;
