import { useCallback, useState } from 'react';

type Toggle = [boolean, (force?: boolean) => void, () => void, () => void];

const useToggle = (initialValue = false): Toggle => {
  const [value, setValue] = useState(initialValue);
  const toggle = useCallback((force?: boolean) => setValue((v) => force ?? !v), []);
  const open = useCallback(() => setValue(true), []);
  const close = useCallback(() => setValue(false), []);
  return [value, toggle, open, close];
};

export default useToggle;
