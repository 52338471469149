import type { PublicListing } from '@wandercom/listings-defs';

import { getImageUrl } from '@/features/properties/actions/image';
import normalizePrice from './normalizePrice';

export type PropertyToProductObject = Pick<PublicListing, 'id' | 'name' | 'slug' | 'address' | 'medias' | 'categories'>;

const propertyToProductObject = (
  property: PropertyToProductObject,
  price: string | number | null = null,
  nights: number | null = null
) => {
  const { id: product_id, name, slug, address } = property;

  const priceNormalized = normalizePrice(price);

  return {
    product_id,
    name,
    brand: 'Wander', // Make dynamic if we ever add additional brands
    image_url: getImageUrl(property),
    city: address.city,
    region: address.state,
    country: 'United States', // Make dynamic if we ever add countries
    state: address.state,
    nights,
    price: priceNormalized, // the average price per night
    sku: '0',
    quantity: nights,
    url: `https://wwww.wander.com/property/${slug}`,
    categories: property.categories ? property.categories?.map((category) => category.name) : [],
  };
};

export type ProductObject = ReturnType<typeof propertyToProductObject>;

export default propertyToProductObject;
