'use client';

import clx from '@wander/ui/src/utils/clx';

import canDisplayRecommendedDates from '../../actions/canDisplayRecommendedDates';
import type { PropertyLandingPage } from '../../actions/toLandingProperty';

import JoinWaitList from './JoinWaitList';
import JoinWaitListWithUserWrapper from './JoinWaitList/JoinWaitListWithUserWrapper';
import NextAvailable from './NextAvailable';
import Property_Carousel from './Property_Carousel';
import Features from './Property_Features';
import Property_Info from './Property_Info';
import './style.css';
import AddToWishListFallback from '@/features/properties/components/Property/AddToWishList/AddToWishListFallback';
import propertyToProductObject from '@/features/tracking/actions/propertyToProductObject';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import useLinkToProperty from './useLinkToProperty';

const AddToWishList = dynamic(() => import('@/features/properties/components/Property/AddToWishList'), {
  ssr: false,
  loading: () => <AddToWishListFallback className='border-none absolute right-4 top-4' />,
});

type Props = {
  property: PropertyLandingPage;
  className?: string;
  carouselClassName?: string;
  infoClassName?: string;
  lazyLoad?: boolean;
};

type PropertyCardProps<T extends React.ElementType = 'div'> = Props & {
  as?: T;
} & Omit<React.ComponentPropsWithoutRef<T>, 'as' | keyof Props>;

export const PropertyCard = <T extends React.ElementType = 'div'>({
  as,
  children,
  property,
  className,
  carouselClassName,
  infoClassName,
  lazyLoad = false,
  ...props
}: PropertyCardProps<T>) => {
  const Component = as || 'div';
  return (
    <Component
      className={clx(
        `
      card-wrapper
      group
      pointer-events-auto
      relative
      grid
      aspect-square
      h-full
      w-full
      grid-rows-[1fr_auto]
      gap-3
      rounded-lg
      group-[.is]/marker:!grid-rows-[200px_auto]
      sm:aspect-auto
      sm:grid-rows-[294px_auto]
    `,
        className
      )}
      {...props}
    >
      <Property_Carousel
        property={property}
        loading={lazyLoad ? 'lazy' : 'eager'}
        fetchPriority={lazyLoad ? 'auto' : 'high'}
        className={clx(
          'group-[.is]/marker:!transform-none overflow-hidden group-[.is]/marker:rounded-b-none',
          carouselClassName
        )}
        imagesClassName='group-[.is]/marker:rounded-b-none'
      />
      <div
        className={clx(
          'group-[.is]/marker:rounded-b-lg group-[.is]/marker:bg-black group-[.is]/marker:px-3 group-[.is]/marker:pb-3',
          infoClassName
        )}
      >
        <Property_Info property={property} />
        {children}
      </div>
      {/* @ts-ignore */}
      <AddToWishList product={propertyToProductObject(property)} className='border-none absolute right-4 top-4' />
    </Component>
  );
};

const BookableProperty = (props: Props) => {
  const property = props.property;
  return (
    <PropertyCard as={Link} {...props} {...useLinkToProperty(property)}>
      <div
        className={clx(
          'grid w-full',
          canDisplayRecommendedDates(property) && 'grid-cols-[min-content,min-content,min-content] w-full'
        )}
      >
        <NextAvailable property={property} />
        <Features property={property} className='flex items-center gap-1 text-sm pl-1' small />
      </div>
    </PropertyCard>
  );
};

const PropertyWrapper = (props: Props) => {
  const property = props.property;

  if (!property.slug) {
    return undefined;
  }

  return property.bookable ? (
    <BookableProperty property={property} {...props} />
  ) : (
    <label
      htmlFor={`join-waitlist-${property.slug}`}
      className='block cursor-pointer group-[.is]/marker:px-0 group-[.is]/marker:py-0'
    >
      <PropertyCard {...props}>
        <JoinWaitListWithUserWrapper slug={property.slug}>
          <JoinWaitList property={property} />
        </JoinWaitListWithUserWrapper>
      </PropertyCard>
    </label>
  );
};

export default PropertyWrapper;
