'use client';

import Icon from '@wander/ui/src/components/Icon';

import type { PropertyLandingPage } from '../../../actions/toLandingProperty';
import useIsWaitListed from '../../../hooks/useIsWaitListed';
import JoinWaitListDialog from './JoinWaitListDialog';
import WaitListJoined from './WaitListJoined';

const JoinWaitList = ({ property }: { property: PropertyLandingPage }) => {
  const isWaitListed = useIsWaitListed(property.id);

  if (isWaitListed == undefined) {
    return (
      <button className='flex items-center text-sm text-w-3005'>
        Join the waitlist <Icon name='chevron-right' className='top-0 h-[20px] w-[20px]' />
      </button>
    );
  }

  return isWaitListed ? <WaitListJoined /> : <JoinWaitListDialog property={property} />;
};

export default JoinWaitList;
