import clx from '@wander/ui/src/utils/clx';

import { formatInTimeZone } from 'date-fns-tz';
import getNextAvailableDate from '../../actions/getNextAvailableDate';
import type { PropertyLandingPage } from '../../actions/toLandingProperty';

type Props = {
  property: PropertyLandingPage;
};

const NextAvailable = ({ property }: Props) => {
  const nextAvailableDate = getNextAvailableDate(property);

  return nextAvailableDate && nextAvailableDate.checkIn && nextAvailableDate.checkOut ? (
    <>
      <span className='whitespace-nowrap text-sm text-w-4004 '>Next available:&nbsp;</span>
      <span className='whitespace-nowrap text-6-white text-[13px] border-r border-3-primary-grey pr-2'>
        {`${formatInTimeZone(nextAvailableDate.checkIn, property.timezone, 'MMM d')} to ${formatInTimeZone(
          nextAvailableDate.checkOut,
          property.timezone,
          'MMM d'
        )}`}
      </span>
    </>
  ) : null;
};

export default NextAvailable;
